/*
  Validation rules for forms. Usage example:

  <template>
    <q-input
      v-model="email"
      label="Email"
      :rules="[ruleRequired, ruleEmail]"
    />
  </template>

  <script>
    import { ruleEmail, ruleRequired } from '@/utils/validation'
    methods: {
      ruleEmail,
      ruleRequired,
      ...
    }
  </script>
*/

const emailPattern =
  /^(?=[a-zA-Z0-9@._%+-]{6,254}$)[a-zA-Z0-9._%+-]{1,64}@(?:[a-zA-Z0-9-]{1,63}\.){1,8}[a-zA-Z]{2,63}$/
// const phonePattern = /^(\+61\d{9}|04\d{8}|9\d{7})$/
const phonePattern = /^\d{10,}$/

export const ruleEmail = val => emailPattern.test(val) || 'Invalid email'
export const rulePhone = val => phonePattern.test(val) || 'Invalid phone number'
export const ruleRequired = val =>
  (Array.isArray(val) && val.length) ||
  (!Array.isArray(val) && !!val) ||
  'Answer required'

export const rulePhoneNotRequired = val =>
  !val || phonePattern.test(val) || 'Invalid phone number'

export const rule6CharactersOnly = val => val.length === 6 || false
export const ruleNumbers = val => /^[0-9]+$/.test(val) || false
