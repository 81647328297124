<template>
  <GoogleMap
    :api-key="key"
    :libraries="libraries"
    map-id="88c2fb15530aeb22"
    gesture-handling="cooperative"
    :center="props.center"
    :zoom="props.currentZoom"
    :style="props.styling"
  >
    <Marker
      v-for="(marker, index) in props.markers"
      :key="index"
      @dragend="pinMoved"
      :options="{
        position: marker.position,
        draggable: props.draggable,
        icon: { url: '/img/icons/pin.svg' }
      }"
    />
  </GoogleMap>
</template>

<script setup>
import { ref } from 'vue'
import { GoogleMap, Marker } from 'vue3-google-map'

const key = ref(import.meta.env.VITE_APP_GOOGLE_PLACES)
const libraries = ref(['places'])

const props = defineProps({
  center: Object,
  currentZoom: Number,
  draggable: { type: Boolean, default: false },
  markers: Array,
  styling: String
})

const emit = defineEmits(['pinMoved'])

const pinMoved = event => {
  emit('pinMoved', event.latLng)
}
</script>
