import { defineStore } from 'pinia'

export const useTicketStore = defineStore('tickets', {
  state: () => ({
    openDialog: false,
    refetchList: false,
    serviceList: [],
    serviceFaultFormData: null,
    billingFormData: null,
    salesFormData: null,
    sharedFormData: null,
    currentTab: null
  }),
  actions: {
    toggleDialog() {
      this.openDialog = !this.openDialog
    },
    toggleRefetch() {
      this.refetchList = !this.refetchList
    },
    setServiceList(data) {
      this.serviceList = data
    },
    setServiceFaultFormData(data) {
      this.serviceFaultFormData = data
    },
    setBillingFormData(data) {
      this.billingFormData = data
    },
    setSalesFormData(data) {
      this.salesFormData = data
    },
    setSharedFormData(data) {
      this.sharedFormData = data
    },
    resetFormData() {
      this.serviceFaultFormData = null
      this.billingFormData = null
      this.salesFormData = null
      this.sharedFormData = null
    },
    setCurrentTab(tab) {
      this.currentTab = tab
    }
  }
})
