<template>
  <div class="p-4 py-6">
    <q-form @submit="handleSignIn">
      <div v-if="error" class="mb-4">
        <span class="text-red-500 font-medium">{{ error }}</span>
      </div>
      <div class="mb-4">
        <label for="email" class="pb-2">Email</label>
        <q-input
          v-model="email"
          id="email"
          type="email"
          placeholder="name@host.com"
          filled
          hide-bottom-space
          :rules="[ruleRequired, ruleEmail]"
        />
      </div>

      <div class="mb-4">
        <label for="password">Password</label>
        <q-input
          v-model="password"
          id="password"
          type="password"
          placeholder="Password"
          filled
          hide-bottom-space
          :rules="[ruleRequired]"
        />
        <router-link
          :to="{
            name: 'forgot-password'
          }"
          class="text-primary"
        >
          Forgot your password?
        </router-link>
      </div>

      <q-btn
        class="full-width bg-primary text-white"
        label="Sign in"
        type="submit"
        size="lg"
        no-caps
        :loading="isLoading"
      />
    </q-form>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import { signIn, signOut } from 'aws-amplify/auth'
import { useRoute, useRouter } from 'vue-router'

import { useNewAuthStore } from '@/stores'

import { ruleRequired, ruleEmail } from '@/utils/validation'

const router = useRouter()
const route = useRoute()

const newAuthStore = useNewAuthStore()

const email = ref(null)
const password = ref(null)

const isLoading = ref(false)
const error = ref(null)

onMounted(async () => {
  newAuthStore.$reset()

  try {
    await signOut()
  } catch (e) {
    console.log({ e })
  }
})

const handleSignIn = async () => {
  try {
    isLoading.value = true
    error.value = null

    const { nextStep } = await signIn({
      username: email.value,
      password: password.value
    })

    // TODO: move this method to single file for reusability
    switch (nextStep.signInStep) {
      case 'CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED': {
        newAuthStore.initiateChangePassword(true)
        newAuthStore.setEmail(email.value)

        router.push({
          name: 'change-password',
          query: route.query
        })
        return
      }

      case 'CONTINUE_SIGN_IN_WITH_TOTP_SETUP': {
        const { getSetupUri, sharedSecret } = nextStep.totpSetupDetails
        const setupUri = getSetupUri('Channel Portal (Swoop)', email.value)

        newAuthStore.initiateMfaSetup(true)
        newAuthStore.setLink(setupUri?.href)
        newAuthStore.setSecret(sharedSecret)

        router.push({
          name: 'mfa-setup',
          query: route.query
        })
        return
      }

      case 'CONFIRM_SIGN_IN_WITH_TOTP_CODE': {
        newAuthStore.initiateSignIn(true)
        newAuthStore.setMfaType('TOTP')

        router.push({
          name: 'mfa-verification',
          query: route.query
        })
        return
      }

      case 'CONFIRM_SIGN_IN_WITH_SMS_CODE': {
        const { codeDeliveryDetails } = nextStep
        const { destination } = codeDeliveryDetails

        newAuthStore.initiateSignIn(true)
        newAuthStore.setMfaType('SMS')
        newAuthStore.setDestination(destination)

        router.push({
          name: 'mfa-verification',
          query: route.query
        })
        return
      }

      case 'CONTINUE_SIGN_IN_WITH_MFA_SELECTION': {
        newAuthStore.initiateSignIn(true)
        newAuthStore.setMfaOptions(nextStep?.allowedMFATypes)

        router.push({
          name: 'mfa-selection',
          query: route.query
        })

        return
      }

      case 'RESET_PASSWORD': {
        newAuthStore.setMandatoryPasswordChange(true)

        router.push({
          name: 'forgot-password',
          query: route.query
        })
        return
      }

      // Not needed since custom auth isn't enabled
      // case 'CONFIRM_SIGN_IN_WITH_CUSTOM_CHALLENGE':
      //   break

      // Not needed, done in onboarding?
      // case 'CONFIRM_SIGN_UP':
      //   break

      case 'DONE': {
        const redirect = route.query?.redirect || '/'
        router.push(redirect)
        return
      }
    }

    isLoading.value = false
  } catch (err) {
    isLoading.value = false

    error.value = err?.message
  }
}
</script>
