<!-- 
  Shows App spinner with optional message. UI is blocked when this spinner is visible.
-->
<template>
  <transition name="fade">
    <div class="block-ui">
      <div class="loading-spinner">
        <q-spinner
          color="primary"
          size="7em"
          :thickness="3"
          aria-label="loading"
        />
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'AppSpinner',
  props: {
    size: {
      type: String,
      default: '70'
    },
    width: {
      type: String,
      default: '6'
    },
    colour: {
      type: String,
      default: 'primary'
    }
  }
}
</script>

<style scoped lang="scss">
.block-ui {
  position: fixed;
  z-index: 999999;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.15);
  display: flex;
  align-items: center;
  justify-content: center;
}

.loading-spinner {
  width: 180px;
  height: 190px;
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 0.9);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

// Vue transition classes
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
