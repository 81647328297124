<template>
  <q-dialog v-model="open" persistent>
    <div
      class="!rounded-lg bg-white px-2 pb-4 pt-5 mt-5 text-center shadow-xl transition-all sm:!max-w-full sm:w-[44rem] sm:px-4 top-0 relative"
    >
      <div class="flex justify-end">
        <q-btn flat v-close-popup>
          <q-icon name="close" />
        </q-btn>
      </div>
      <div>
        <h5 class="text-xl text-primary font-bold">Enhance your security</h5>
      </div>
      <div class="text-left border p-4">
        <span class="text-primary font-bold">Set Up MFA with Swoop Today</span>
        <div class="border-l-2 border-black text-gray-800 pl-2 mt-4">
          <p>
            Swoop has introduced Multi-Factor Authentication (MFA) to its portal
            and strongly encourages all users to set it up promptly. Beginning
            November 4th, 2024, MFA will be mandatory for accessing your
            account.
          </p>
          <p>
            This proactive measure by Swoop aims to bolster security and protect
            user data against increasingly sophisticated cyber threats. By
            enabling MFA, you not only enhance the security of your account but
            also ensure safer interactions with Swoop’s services. Take the
            initiative today to set up MFA and fortify your account against
            unauthorized access.
          </p>
        </div>
      </div>
      <div class="mt-4 flex justify-center gap-2">
        <router-link to="/account">
          <q-btn
            color="primary"
            class="btn-primary"
            label="SET UP MFA"
            v-close-popup
          />
        </router-link>

        <q-btn color="primary" label="LATER" v-close-popup />
      </div>
    </div>
  </q-dialog>
</template>

<script setup>
import { ref } from 'vue'

const open = ref(true)
</script>
