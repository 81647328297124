import API from './apiHelper'
import { useAuthStore } from '@/stores'

export default {
  get() {
    return API.get('/whoami')
  },
  getSalesforceManager() {
    const userStore = useAuthStore()
    const partyId = userStore.userPartyId
    return API.get(`party/${partyId}/salesforceAccountManager`, {
      spinner: false
    })
  }
}
