<!--
  vue3-google-map doesn't have built in Google Map Autocomplete component
  have to create custom one with '@googlemaps/js-api-loader'
-->
<template>
  <input
    type="text"
    :placeholder="prop.placeholder || 'Type to search...'"
    @focus="inputFocus"
    @blur="inputBlur"
    ref="autocompleteRef"
  />
</template>

<script setup>
import { ref, onMounted } from 'vue'
import { Loader } from '@googlemaps/js-api-loader'

let loaderInstance

const prop = defineProps(['placeholder'])
const emit = defineEmits(['focus', 'blur', 'placeChanged'])

const autocompleteRef = ref(null)

const setupAutocomplete = google => {
  const autocomplete = new google.maps.places.Autocomplete(
    autocompleteRef.value,
    {
      componentRestrictions: {
        country: 'au'
      }
    }
  )

  autocomplete.addListener('place_changed', () => {
    const place = autocomplete.getPlace()
    emit('placeChanged', place)
  })

  autocompleteRef.value.focus()
}

const loadMapsApi = () => {
  try {
    loaderInstance = new Loader({
      apiKey: import.meta.env.VITE_APP_GOOGLE_PLACES,
      libraries: ['places']
    })
  } catch (error) {
    // Loader instantiated again with different options, which isn't allowed by js-api-loader
    // console.log(error)
  }
}

onMounted(() => {
  loadMapsApi()

  loaderInstance.load().then(setupAutocomplete)
})

const inputFocus = () => {
  emit('focus')
}

const inputBlur = () => {
  emit('blur')
}
</script>
