<template>
  <div class="min-h-screen w-full bg-neutral-400">
    <div class="flex justify-center">
      <div class="w-96 my-8 bg-white shadow-gray-500 shadow-xl">
        <div class="bg-primary">
          <img src="/img/icons/swoop-logo-white.svg" />
        </div>
        <slot />
      </div>
    </div>
  </div>
</template>

<script setup></script>
