<!-- 
  This template displays a modal confirmation message based on any
  configuration set in $store.state.confirm. If configuration is null
  then no message will be displayed.

  Params:

    - text (string): Primary message text
    - detail (string): Optional secondary message text
    - okText (string): Optional text for the confirm button (defaults to "OK")
    - cancelText (string): Optional cancel button text (defaults to "Cancel"). If set to null or empty, no cancel button is displayed.
    - icon (string): Optional icon name (from Material Icons) to use for the main icon. Otherwise a question mark icon will be used if the confirmation has confirm & cancel buttons, and otherwise an info icon will be used.

  EXAMPLE USAGE:

    const isDelete = await this.$store.dispatch('confirm', {
      icon: "delete",
      text: "Delete record?",
      detail: "This will delete the customer record.",
      okText: "Delete"
    })

-->

<template>
  <q-dialog :model-value="!!params" @hide="cancel">
    <q-card v-if="!!params" class="confirm">
      <q-card-section class="row">
        <q-avatar
          :icon="icon"
          color="primary"
          text-color="white"
          :font-size="params.icon ? '0.7em' : null"
        />
        <div class="content">
          <div class="message bold">{{ params.text }}</div>
          <div class="detail" v-if="params.detail">{{ params.detail }}</div>
        </div>
      </q-card-section>

      <q-card-actions align="right">
        <q-btn
          v-if="cancelButtonText"
          no-caps
          flat
          :label="cancelButtonText"
          color="primary"
          @click="cancel"
        />
        <q-btn
          v-if="confirmButtonText"
          no-caps
          unelevated
          :label="confirmButtonText"
          color="primary"
          @click="confirm"
        />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script>
import { mapState, mapActions } from 'pinia'
import { fasInfo, fasQuestion } from '@quasar/extras/fontawesome-v5'
import { useAppStore } from '@/stores'

export default {
  name: 'Confirm',

  computed: {
    ...mapState(useAppStore, ['confirmData']),
    params() {
      return this.confirmData
    },
    confirmButtonText() {
      return this.params.okText || 'OK'
    },
    cancelButtonText() {
      if (this.params.cancelText === undefined) {
        return 'Cancel'
      }
      return this.params.cancelText || ''
    },
    icon() {
      let isQuestion = !!this.cancelButtonText
      return (
        this.params.icon || (isQuestion ? this.questionIcon : this.infoIcon)
      )
    }
  },
  methods: {
    ...mapActions(useAppStore, ['updateConfirmData']),
    confirm() {
      this.params.onConfirm()
      this.updateConfirmData()
    },
    cancel() {
      if (this.params) {
        if (this.cancelButtonText) {
          this.params.onCancel() // Only trigger cancel if cancel button available
        } else {
          this.params.onConfirm()
        }

        this.updateConfirmData()
      }
    }
  },

  created() {
    // Make FontAwesome SVG icons usable inside template
    this.infoIcon = fasInfo
    this.questionIcon = fasQuestion
  }
}
</script>

<style scoped>
.confirm {
  min-width: 300px;
}
.content {
  margin: 1em 1em 0 1em;
  flex: 1;
}
.message {
  font-size: 16px;
}
.bold {
  font-weight: 500;
}
.detail {
  margin-top: 0.5em;
}
</style>
