import router from '@/router'
import { Hub } from 'aws-amplify/utils'
import UserInfoStore from './user-info-store'

const initAuthListener = () => {
  Hub.listen('auth', async data => {
    switch (data.payload.event) {
      case 'signedIn': {
        UserInfoStore.setLoggedIn(true)

        const redirectPath = localStorage.getItem('redirectAfterLogin')
        const next =
          redirectPath && redirectPath !== '/logout' ? redirectPath : '/'
        localStorage.removeItem('redirectAfterLogin')

        router.push(next)
        break
      }
    }
  })
}

export { initAuthListener }
