import { defineStore } from 'pinia'

export const useNewAuthStore = defineStore('newAuth', {
  state: () => ({
    signInInitiated: false,
    changePasswordInitiated: false,
    mfaSetupInitiated: false,
    link: null,
    secret: null,
    mandatoryPasswordChange: false,
    email: null,
    medium: null,
    destination: null,
    mfaOptions: [],
    mfaType: null
  }),
  actions: {
    initiateSignIn(state) {
      this.signInInitiated = state
    },
    initiateChangePassword(state) {
      this.changePasswordInitiated = state
    },
    initiateMfaSetup(state) {
      this.mfaSetupInitiated = state
    },
    setLink(link) {
      this.link = link
    },
    setSecret(secret) {
      this.secret = secret
    },
    setEmail(email) {
      this.email = email
    },
    setMedium(medium) {
      this.medium = medium
    },
    setDestination(destination) {
      this.destination = destination
    },
    setMfaOptions(options) {
      this.mfaOptions = options
    },
    setMfaType(type) {
      this.mfaType = type
    },
    setMandatoryPasswordChange(state) {
      this.mandatoryPasswordChange = state
    }
  }
})
