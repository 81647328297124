import * as Sentry from '@sentry/vue'

export function initSentry(app) {
  Sentry.init({
    app,
    dsn: import.meta.env.VITE_APP_SENTRY_DSN,
    environment: import.meta.env.NODE_ENV,
    attachStacktrace: true,
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    ignoreErrors: ['ResizeObserver loop limit exceeded'],
    beforeSend: event => {
      // Check if it is an exception, and if so, show the report dialog
      if (event.exception && import.meta.env.NODE_ENV === 'production') {
        Sentry.showReportDialog({ eventId: event.event_id })
      }
      return event
    }
  })
}
